<template>
  <div class="news">
    <!-- Header -->
    <section class="head">
      <div class="left">
        <a @click.prevent="goTo('/home')">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span>最新消息</span>
      <div class="right"></div>
    </section>
    <!-- Main -->
    <section class="main">
      <div
        class="item"
        v-for="(article, index) in articles"
        :key="'article' + index"
      >
        <img :src="article.imageUrl" />
        <span class="title">{{ article.title }}</span>
        <span v-html="article.htmlContent"></span>
        <a @click.prevent="goTo(`/articles/${article.id}`)">
          <span>查看更多</span>
          <font-awesome-icon
            icon="fa-solid fa-chevron-right"
          ></font-awesome-icon>
        </a>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment-timezone';

export default {
  name: 'Articles',
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
    };
  },
  mounted() {
    this.getArticles();
  },
  computed: {
    ...mapState(['user', 'userInfo']),
    ...mapGetters({
      articles: 'article/getArticles',
    }),
    merchantId() {
      return this.user.user?._json?.merchantId || this.userInfo.merchantId;
    },
  },
  methods: {
    goTo(path) {
      this.$router.push({ path, query: this.$route.query });
    },
    tsToData(ts) {
      return moment(ts * 1000).format('YYYY-MM-DD');
    },
    dummyArticle() {
      let result = [
        {
          id: 1,
          title: '測試最新消息',
          htmlContent:
            '<h2><span style="color: rgb(0, 102, 204);">卡好女王節</span></h2><p>三大支付，消費滿千送百</p>',
          imageUrl:
            'https://storage.googleapis.com/appointed_store_images/tycard2022/home/8caf3ea4-3316-48c5-9c34-46baee1c9c51.png',
          status: 'approved',
          startTs: 1651593600,
          endTs: 1654358399,
          departmentName: '規劃發展科',
          categoryId: 1,
          categoryName: '其它',
        },
        {
          id: 1,
          title: '測試最新消息',
          htmlContent:
            '<h2><span style="color: rgb(0, 102, 204);">卡好女王節</span></h2><p>三大支付，消費滿千送百</p>',
          imageUrl:
            'https://storage.googleapis.com/appointed_store_images/tycard2022/home/8caf3ea4-3316-48c5-9c34-46baee1c9c51.png',
          status: 'approved',
          startTs: 1651593600,
          endTs: 1654358399,
          departmentName: '規劃發展科',
          categoryId: 1,
          categoryName: '其它',
        },
        {
          id: 1,
          title: '測試最新消息',
          htmlContent:
            '<h2><span style="color: rgb(0, 102, 204);">卡好女王節</span></h2><p>三大支付，消費滿千送百</p>',
          imageUrl:
            'https://storage.googleapis.com/appointed_store_images/tycard2022/home/8caf3ea4-3316-48c5-9c34-46baee1c9c51.png',
          status: 'approved',
          startTs: 1651593600,
          endTs: 1654358399,
          departmentName: '規劃發展科',
          categoryId: 1,
          categoryName: '其它',
        },
      ];
      return new Promise(resolve => {
        setTimeout(() => {
          resolve({ data: result });
        }, 300);
      });
    },
    callArticleAPI() {
      var config = {
        method: 'get',
        url: `${this.apiHost}/articles/v1/merchants/${this.merchantId}/articles`,
        headers: {},
      };

      return this.$http(config);
    },
    getArticles() {
      this.callArticleAPI()
        //   this.dummyArticle()
        .then(res => {
          console.log('getArticles res: ', res);
          this.$store.commit(
            'article/setArticles',
            JSON.parse(JSON.stringify(res.data))
          );
        })
        .catch(err => {
          console.log('getArticles err: ', err);
        });
    },
  },
};
</script>
